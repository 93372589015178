
<template>
  <div class="wchat-box d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <div class="wma-box">
                  <div class="return-icon">
                    <svg @click="toGo" style="margin-bottom:.2rem;cursor:pointer" height="35" width="35" xmlns="http://www.w3.org/2000/svg" class="icon icon-xxl" viewBox="0 0 512 512" role="img">undefined<path fill="var(--ci-primary-color, currentColor)" d="M256,16.042c-132.548,0-240,107.451-240,240s107.452,240,240,240,240-107.452,240-240S388.548,16.042,256,16.042ZM403.078,403.12A207.253,207.253,0,1,1,447.667,337,207.364,207.364,0,0,1,403.078,403.12Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="272.614 164.987 249.986 142.36 136.305 256.041 249.986 369.722 272.614 347.095 197.56 272.041 385 272.041 385 240.041 197.56 240.041 272.614 164.987" class="ci-primary"></polygon></svg>
                    <h3 style="margin-left: .88rem">{{ $t('avatarSelect.WxBind') }}</h3>
                </div>
                  <div class="tip">{{ $t('avatarSelect.MaTip') }}</div>
                  <div class="ma-box">
                    <!-- <vueQr
                      :text="erSrc"
                      :size="size"
                      :logoScale="logoScale"
                    ></vueQr> -->
                    <img style="width:388px;height:388px" :src="`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${erCode}`" alt="">
                  
                  </div>
                  
                  <!-- <div class="success-text">{{ message }}</div> -->
                  <div v-if="suc">
                    <el-alert
                      :title="message"
                      type="success"
                    >
                    </el-alert>
                  </div>
                  <!-- <div class="success-text" v-if="code == 200">{{ message }}</div> -->
                  <!-- <div class="success-text" v-else-if="code == 401">{{ message }}</div> -->
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
// import vueQr from "vue-qr";
import { getWxQrCode } from "../../api/http";
export default {
  // components: { vueQr },
  data() {
    return {
      erCode: "",
      logoScale: 0.2,
      size: 388,
      qr_id: "",
      code: null,
      message: "",
      timer: null,
      suc: false
    };
  },
  watch: {
      code: {
          handler(val) {
              if(val == 200) {
                  clearInterval(this.timer)
                  this.timer = null
                  this.suc = true
                  this.message = this.$t('avatarSelect.BSuccess')
                  setTimeout(() => {
                    this.$router.push("/users/account")
                  }, 1000)
                  return
                //  clearInterval(this.timer)
                  // this.timer = null
              }
              if(val == 403) {
                  this.message = "..."
                  clearInterval(this.timer)
                  this.timer = null
              }else {
                  this.message = ""
              }
          }
      }
  },
  computed: {
    // https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQHH7zwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyTHJZekJwQXFmVUQxMGhCb056MUoAAgQJHhhjAwQIBwAA
    erSrc() {
      return (
        "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket="+this.erCode
      );
    },
    getQrId() {
        return this.qr_id
    }
  },
  created() {
    // this.getWcMa();
    // this.setTimeWxLogin()
    this.getWcMa();
    setTimeout(() => {
      this.setTimeWxLogin()
    }, 3000)
  },
  mounted() {
    
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  destroyed() {
      clearInterval(this.timer)
      this.timer = null
  },
  methods: {
    toGo() {
      this.$router.go(-1)
    },
    // 獲取微信登陸二維碼
    getWcMa() {
      let data = {
        type: "get_wx_qr_code",
        uid: localStorage.getItem("uid"),
      };
      getWxQrCode(data).then((res) => {
        console.log("res==>", res);
        if (res.code == 200) {
          this.erCode = res.data.ticket;
          this.qr_id = res.data.id;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    setTimeWxLogin() {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.reqWxLogin()
        }, 1000)
    },
    // 賬戶綁定微信
    reqWxLogin(qid) {
      if(this.getQrId == "") {
        this.message = "..."
        return
      }
      let data = {
        type: "bind_wx",
        qr_id: this.getQrId,
        // qr_id: qid,
        uid: localStorage.getItem('uid')
      };
      let formData = new FormData();
      for (let k in data) {
        formData.append(k, data[k]);
      }
     this.$axios({
        method: "POST",
        url: "/request/user.php",
        data: formData,
      }).then((code) => {
          console.log('code==>', code)
        if (code.data.code == 200) {
            // this.$message.success(code.data.message)
             this.code = code.data.code
            this.message = code.data.message
            clearInterval(this.timer)
            this.timer = null
            return
        } else if(code.data.code == 403) {
            this.code = code.data.code
            this.message = code.data.message
        } 
        else {
        //   this.$message.error(code.data.message);
        }
      })
    },
  },
};
</script>
<style scoped>
.wma-box {
  padding: 20px;
}
.tip {
  color: #3b4c64;
  font-size: 16px;
}
.success-text {
  color: #2cdd71;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}
.ma-box{
  text-align: center;
}
.return-icon{
  display: flex;
  align-items: center;
}
</style>